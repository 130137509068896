<script>
    export let paymentData;
    export let chatbotId;
    const apiUrl = process.env.API_URL;

    const appId = "sq0idp-Ib_OtJj0Iv-PCkuHxUrkfQ";
    let card;
    let locationId = paymentData?.location_id;
    $: loading = false
    $: paid = false
    $: paymentFailed = false

    async function initializePaymentForm() {
        if (!Square) {
            throw new Error("Square.js failed to load properly");
        }
        const payments = Square.payments(appId, locationId);
        try {
            card = await payments.card();
            console.log("BEFORE ATTACH CARD");
            await card.attach("#card-container");
            console.log("card", card);
        } catch (e) {
            console.log("Initializing Card failed", e);
            return;
        }
    }

    async function tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === "OK") {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }
            throw new Error(errorMessage);
        }
    }

    async function handlePaymentMethodSubmission() {
        try {
            loading = true
            const token = await tokenize(card);
            console.log("Token: ", token);
            const paymentResponse = await (await fetch(
                `${apiUrl}/api/public/square/payment`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: token,
                        amount: paymentData?.amount,
                        customerId: paymentData?.customer_id,
                        locationId: paymentData?.location_id,
                        reference_id: paymentData?.reference_id,
                        chatbotId: chatbotId,
                    }),
                },
            )).json();
            loading = false
            if(paymentResponse.paymentStatus == "COMPLETED") {
                paid = true
                paymentFailed = false;
            } else {
                paymentFailed = true
            }
            // console.log(
            //     "\n paymentResponse",
            //     paymentResponse,
            //     "\n payment request: ",
            //     {
            //         token: token,
            //         amount: paymentData?.amount,
            //         customerId: paymentData?.customer_id,
            //         locationId: paymentData?.location_id,
            //         reference_id: paymentData?.reference_id,
            //         chatbotId: chatbotId,
            //     },
            //     "\n paymentData",
            //     paymentData,
            // );
        } catch (e) {
            console.error(e.message);
            loading = false
            paymentFailed = true
        }
    }
</script>

<div>
    {#if (paymentData && !paid)}
        <form on:submit|preventDefault={handlePaymentMethodSubmission}>
            {#await initializePaymentForm()}
                <p>Loading...</p>
            {:catch error}
                <p>{error}</p>
            {/await}
            <div id="card-container"></div>
            <button disabled={loading}>{loading ? "Loading..." : `Pay $${paymentData?.amount / 100 ?? 0}`}</button>
        </form>
    {/if}
    {#if paid}
      <div>
          <h2>Thank you. Your payment has been received.</h2>
      </div>
    {/if}
    {#if paymentFailed}
      <div>
          <h2>There seems to be an issue with your payment. Please contact the support team.</h2>
      </div>
    {/if}
</div>

<style>
    button {
        color: #ffffff;
        background-color: #006aff;
        border-radius: 6px;
        cursor: pointer;
        border-style: none;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 16px;
        width: 100%;
    }

    button:hover {
        background-color: #005fe5;
    }

    button:active {
        background-color: #0055cc;
    }

    button:disabled {
        background-color: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.3);
    }
</style>
