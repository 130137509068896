import colorString from '../color/colorString';
import hsl from '../color/hsl';

const lightness = (baseColor, key) => {
  if (typeof key !== 'number') return baseColor;
  const base = hsl(baseColor);
  const targetL = Math.min(Math.max(key, 0), 100);
  return colorString(Object.assign(Object.assign({}, base), {
    l: targetL
  }));
};

export default lightness;