// generated during release, do not modify

/**
 * The current version, as set in package.json.
 *
 * https://svelte.dev/docs/svelte-compiler#svelte-version
 * @type {string}
 */
export const VERSION = '4.2.17';
export const PUBLIC_VERSION = '4';
