import rotation from '../mappings/rotation';
import saturation from '../mappings/saturation';
import normalize from '../normalize';
export default function harmony(baseColor) {
  return {
    primary: normalize(baseColor),
    secondary: saturation(rotation(baseColor, 30), 15),
    accent: saturation(rotation(baseColor, 90), 80),
    neutral: saturation(baseColor, 5),
    error: '#BB2211'
  };
}