<script>
    import Carousel from "./Carousel.svelte";
    import CarouselCard from "./CarouselCard.svelte";
    import { useTooltip } from '@untemps/svelte-use-tooltip'
    import dayjs from "dayjs";
    import dayjsRelativeTime from "dayjs/plugin/relativeTime";
    import { parse } from "postcss";
    import { afterUpdate, onMount } from "svelte";
    import untruncateJson from "untruncate-json";
    dayjs.extend(dayjsRelativeTime);

    export let scheme;
    export let chatBotData;
    export let message;
    export let chatbotId;
    let messages = message.message;
    $: isCarouselLoading = message?.isCarouselLoading
    $: jsonMessage = messages;
    let parsedMessages = [];
    $: mounted = false

    onMount(() => {
        mounted = true
    })

    // console.log("jsonMessage", jsonMessage);

    // $: {
    //     try {
    //         parsedMessages = JSON.parse(untruncateJson(jsonMessage));
    //     } catch (error) {
    //         console.error("Failed to parse JSON message:", error);
    //         parsedMessages = [];
    //     }
    // }

    $: ratingStatus = message?.chatRating;
    const apiUrl = process.env.API_URL;

    // console.log(
    //     "jsonMessage",
    //     jsonMessage,
    //     "\n untruncateJson(message?.message)",
    //     untruncateJson(message?.message),
    //     "\n JSON.parse(untruncateJson(message?.message))",
    //     JSON.parse(untruncateJson(message?.message)),
    //     "\n message?.message",
    //     message?.message
    // );

    async function toggleThumbs(chatId, rating, chatType) {
        await fetch(
            `${apiUrl}/api/public/chats/${chatId}/ratings`,
            {
                method: "POST",
                body: JSON.stringify({
                    userId: localStorage.getItem("userId-" + chatbotId),
                    sessionId: localStorage.getItem("sessionId-" + chatbotId),
                    chatId,
                    rating,
                    chatType: chatType ?? 'text'
                }),
            }
        );
        ratingStatus = rating;
    }

    function relativeTime(date) {
        return dayjs(date).fromNow();
    }
  </script> 
  

  <div class="mb-[32px]">
    <div
    class="flex-none self-start flex flex-col items-start space-y-1 mr-[8px]"
    >
        <img
            class="rounded-full w-[30px] h-[30px]"
            src={chatBotData.botAvatar}
            alt="Bot Avatar"
        />
    </div>
    
    <Carousel slides={[...JSON.parse(message.message.split('\n\n')[0])]} dots={false} scheme={scheme} isCarouselLoading={isCarouselLoading}>
        
        <span 
            class="bg-white rounded-[100px]
            border-t-2 shadow-[0_2px_4px_2px_rgba(0,0,0,0.06)]" 
            style="display: flex; position: absolute; right: 35px; height: 32px; width: 32px; justify-content:center; align-items:center" 
            slot="left-control">
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7803 3.21967C12.0732 3.51256 12.0732 3.98744 11.7803 4.28033L7.06066 9L11.7803 13.7197C12.0732 14.0126 12.0732 14.4874 11.7803 14.7803C11.4874 15.0732 11.0126 15.0732 10.7197 14.7803L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967L10.7197 3.21967C11.0126 2.92678 11.4874 2.92678 11.7803 3.21967Z"
                    fill="black"
                />
            </svg>
        </span>
        <span 
            class="bg-white rounded-[100px]
            border-t-2 shadow-[0_2px_4px_2px_rgba(0,0,0,0.06)]" 
            style="display: flex; position: absolute; right: 15px; height: 32px; width: 32px; justify-content:center; align-items:center" 
            slot="right-control">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.21967 3.21967C6.51256 2.92678 6.98744 2.92678 7.28033 3.21967L12.5303 8.46967C12.8232 8.76256 12.8232 9.23744 12.5303 9.53033L7.28033 14.7803C6.98744 15.0732 6.51256 15.0732 6.21967 14.7803C5.92678 14.4874 5.92678 14.0126 6.21967 13.7197L10.9393 9L6.21967 4.28033C5.92678 3.98744 5.92678 3.51256 6.21967 3.21967Z"
                    fill="black" />
            </svg>
        </span>
    </Carousel>
    <div
        class="text-[#718096] text-[12px] leading-[16px] font-normal flex flex-wrap justify-between"
        style="width: 85%; float:inline-end; margin-block: 5px"
    >
        <div>
            {#if (message.date) ? relativeTime(message.date) : ""}{(message.date) ? relativeTime(message.date) : ""}{/if}
        </div>
        <div class="flex">
            {#if ratingStatus != false}
            <button 
                class={ratingStatus === false ? 'fade-out' : ''}
                on:click={() => {
                    toggleThumbs(message.id, true, `Carousel: ${JSON.parse(jsonMessage)?.[0]?.category}`)
                }}       
                use:useTooltip={{
                    content: 'Good Response',
                    position: 'left',
                    containerClassName: `tooltip tooltip-right`,
                    animated: true,
                    animationEnterClassName: 'tooltip-enter',
                    animationLeaveClassName: null,
                    enterDelay: 100,
                    leaveDelay: 100,
                    offset: 10
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class={`inline-block w-3 h-3 hover:brightness-0 ` + ((ratingStatus === true) ? "brightness-0" : "")}
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                    />
                </svg>
            </button>
            {/if}

            {#if ratingStatus != true}
            <button 
                class="ml-[5px] {ratingStatus === true ? 'fade-out' : ''}"
                on:click={() => toggleThumbs(message.id, false, `Carousel: ${JSON.parse(jsonMessage)?.[0]?.category}`)} 
                use:useTooltip={{
                    content: 'Bad Response',
                    position: 'left',
                    containerClassName: `tooltip tooltip-right`,
                    animated: true,
                    animationEnterClassName: 'tooltip-enter',
                    animationLeaveClassName: null,
                    enterDelay: 100,
                    leaveDelay: 100,
                    offset: 10
                }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class={`inline-block w-3 h-3 hover:brightness-0 ` + ((ratingStatus === false) ? "brightness-0" : "")}
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384"
                    />
                </svg>
            </button>
            {/if}
        </div>
    </div>
</div>
  
  <style>
  
  </style>