import lightness from './mappings/lightness';
export default function palette(baseColor, colorMapping = lightness, noCache) {
  const cache = {};

  const cachedColorMapping = (baseColor, key) => {
    if (cache[key] === undefined) {
      cache[key] = colorMapping(baseColor, key);
    }

    return cache[key];
  };

  const generate = noCache ? colorMapping : cachedColorMapping;
  return key => key !== undefined ? generate(baseColor, key) : baseColor;
}