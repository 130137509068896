<script>
    import { inview } from "svelte-inview";
    import "./app.css";
    import BotMessage from "./BotMessage.svelte";
    import UserMessage from "./UserMessage.svelte";
    import SuggestedPrompts from "./SuggestedPrompts.svelte";
    import Carousel from './Carousel.svelte'
    import untruncateJson from "untruncate-json";


    import {
        harmony,
        colorScheme,
        lightness,
        rotation,
    } from "simpler-color";
    import { getLuminance, suggestAAColorVariant } from "accessible-colors";
    import { afterUpdate, onMount } from 'svelte';
    import { writable } from "svelte/store";
    import dayjs from "dayjs";
    import dayjsRelativeTime from "dayjs/plugin/relativeTime";
    import LeadCaptureField from "./LeadCaptureField.svelte";
    import CarouselCard from "./CarouselCard.svelte";
    import CarouselMessage from "./CarouselMessage.svelte";
    import { removeJSONBlockMarker } from "./lib";
    import { parse } from "postcss";
    import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
    import 'simplebar/dist/simplebar.css';

    // You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
    import ResizeObserver from 'resize-observer-polyfill';
    window.ResizeObserver = ResizeObserver;
    import Analytics from 'analytics'
    import googleAnalytics from '@analytics/google-analytics'


    dayjs.extend(dayjsRelativeTime);

    const apiUrl = process.env.API_URL;
    const chatbotId = window.xprops.chatbotId;
    const gaID = window.xprops?.gaID
    const isOpen = window.xprops.isOpen;
    const fullScreen = window.xprops.fullScreen;
    let externalData = window.xprops.data;
    const isHelpCenterView = window.xprops.helpCenter;

    const isMobile = window.matchMedia('(max-width: 320px)');

    $: data = {};
    $: dataLoaded = false;
    $: showBot = isOpen;
    $: showBotCount = 0;
    $: messagesBoxStatus = "visible";
    $: scheme = {};
    $: baseColors = {};
    $: messages = [];
    $: fetchBot = {};
    $: fetchLeads = "";
    $: fetchChats = {};
    $: chats = [];
    $: fetchData = {};
    $: animate = "";
    $: promptsExist = false;
    $: showPrompts = true;
    $: showOuterPrompts = true;
    $: showCompletedMessage = false;
    $: leads = false;
    let freshChatConfig = {}
    let addons = {}
    let pollingInterval = false;
    let submitting = false
    let leadFields = [
        {
            key: "leadCaptureCompanyNameToggle",
            title: "Company Name",
            enabled: false,
            show: false,
            value: "",
            editable: true,
            loading: false,
            postKey: "companyName",
            errorMessage: "Please enter a company name"
        },
        {
            key: "leadCaptureNameToggle",
            title: "Name",
            enabled: false,
            show: false,
            value: "",
            editable: true,
            loading: false,
            postKey: "name",
            errorMessage: "Please enter a name"
        },
        {
            key: "leadCaptureEmailToggle",
            title: "Email",
            enabled: false,
            show: false,
            value: "",
            editable: true,
            loading: false,
            regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            postKey: "email",
            errorMessage: "Please enter a valid email address"
        },
        {
            key: "leadCapturePhoneToggle",
            title: "Phone",
            enabled: false,
            show: false,
            value: "",
            editable: true,
            loading: false,
            postKey: "phone",
            errorMessage: "Please enter a phone number"
        },
    ];

    let messagesBox;
    let messageInput;
    let submitBtn;
    let leadForm = false
    let selectedFile = null
    window.xprops.onProps(() => {
        if (window.xprops.data) {
            externalData = window.xprops.data;
            if(externalData?.botAvatar) delete externalData.botAvatar
            data = {...data, ...externalData}
            if (externalData.botName) {
                data.botName = externalData.botName;
            }
            if (externalData.welcomeMessage) {
                data.welcomeMessage = externalData.welcomeMessage;
            }

            if (externalData.suggestedMessage) {
                data.SuggestedMessage = externalData.suggestedMessage;
                promptsExist =
                data?.SuggestedMessage?.filter(
                    (prompt) => prompt.type === "SUGGESTED_MESSAGE" && prompt.title.length >= 1
                ).length >= 1;
            }

            if (externalData.primaryColor) {
                baseColors = harmony(externalData.primaryColor);
                baseColors.secondary = lightness(
                    rotation(externalData.primaryColor, 10),
                    60
                );

                if (getLuminance(externalData.primaryColor) > 0.5) {
                    baseColors.accent = baseColors.secondary;
                } else {
                    baseColors.accent = "#ffffff";
                }

                scheme = colorScheme(baseColors, (colors) => ({
                    header: colors.primary(),
                    headerText: colors.accent(),
                    aiBubble: "#edf2f7",
                    aiBubbleText: suggestAAColorVariant(
                        colors.neutral(90),
                        colors.neutral(98)
                    ),
                    userBubble: colors.primary(),
                    userBubbleText: colors.accent(),
                }));
            }

            if(externalData?.chatbotType) {
                data.chatbotType = externalData.chatbotType;
            }
        }

        if (window.xprops.botAvatar) {
            data.botAvatar = window.xprops.botAvatar;
        }

        if (window.xprops.toggle && showBot === false) {
            toggleChatbot();
        }

        if (window.xprops.postMessage) {
            submitMessage(window.xprops.postMessage);
        }
        if (externalData?.removeBranding != undefined) {
                data.removeBranding = externalData?.removeBranding;
        }
    });

    const userId = localStorage.getItem("userId-" + chatbotId);
    const storedId = localStorage.getItem("sessionId-" + chatbotId);
    const userStore = writable(userId || null);
    const sessionStore = writable(storedId || null);
    sessionStore.subscribe((value) =>
        localStorage.setItem("sessionId-" + chatbotId, value)
    );
    userStore.subscribe((value) =>
        localStorage.setItem("userId-" + chatbotId, value)
    );

    onMount(async () => {
        console.log("CHECKING FOR ANALYTICS FIRED");

        if(window.xprops?.gaID){
        console.log("ANALYTICS FIRED");
            const analytics = Analytics({
        app: 'awesome-app',
        plugins: [
            googleAnalytics({
            measurementIds: [window.xprops?.gaID],
            debug:true,
            cookie_flags: 'SameSite=None;Secure'
            })
        ]
        })

        window.analytics = analytics;
        }

        if (chatbotId !== "previewOnly") {
            fetchBot = await fetch(`${apiUrl}/api/public/chatbot/${chatbotId}`);
            data = await fetchBot.text();
            console.log("data", data);
            data = JSON.parse(data);
            data.botAvatar =
                data.botAvatar ||
                "https://chatspark.s3.amazonaws.com/chatbot-assets/chatspark-avatar.png";
            dataLoaded = true;

            if (data.error) {
                return;
            }
        } else {
            dataLoaded = true;
            data.botName = "Chatbot";
            data.botAvatar =
                "https://chatspark.s3.amazonaws.com/chatbot-assets/chatspark-avatar.png";
            data.primaryColor = "#000000";
            data.error = false;
        }

        if (Boolean(Object.keys(data)?.length)) {
            let isFirst = false
            for (let index = 0; index < leadFields.length; index++) {
                const element = leadFields[index];
                if (data[element?.key]) {
                    leadFields[index].enabled = true;
                    leadFields[index].show = isFirst ? false : true;
                    isFirst = true
                }
            }
        }
        if(window.xprops?.gaID){
            window.analytics.track('chatSessionStarts', {sessionId: localStorage.getItem("sessionId-" + chatbotId), userId: localStorage.getItem("userId-" + chatbotId), chatbotId: chatbotId, cookie_flags: 'SameSite=None; Secure'});
        }
        seenMessage();
        if (isOpen) {
            showBot = false;
            toggleChatbot();
        }

        promptsExist =
            data?.SuggestedMessage?.filter(
                (prompt) => prompt.type === "SUGGESTED_MESSAGE" && prompt.title.length >= 1
            ).length >= 1;

        if (promptsExist) {
            window.xprops.resize({ width: 200, height: 200 });
        }

        baseColors = harmony(data.primaryColor);
        baseColors.secondary = lightness(rotation(data.primaryColor, 10), 60);

        if (getLuminance(data.primaryColor) > 0.5) {
            baseColors.accent = baseColors.secondary;
        } else {
            baseColors.accent = "#ffffff";
        }

        scheme = colorScheme(baseColors, (colors) => ({
            base: colors.primary(),
            header: colors.primary(),
            headerText: colors.accent(),
            aiBubble: "#edf2f7",
            aiBubbleText: suggestAAColorVariant(
                colors.neutral(90),
                colors.neutral(98)
            ),
            userBubble: colors.primary(),
            userBubbleText: colors.accent(),
        }));

        fetchChats = await fetch(
            `${apiUrl}/api/public/chatbot/chats/${localStorage.getItem(
                "userId-" + chatbotId
            )}/${localStorage.getItem("sessionId-" + chatbotId)}`
        );
        chats = await fetchChats.text();
        chats = JSON.parse(chats);
        const userId = chats.userId;
        const sessionId = chats.sessionId;
        chats = chats.chats.reverse();

        userStore.set(userId);
        sessionStore.set(sessionId);

        chats.forEach((chat) => {
            messages = [
                ...messages,
                { message: chat.query, date: chat.createdAt, type: "user" },
                {
                    id: chat.id,
                    message: chat.response,
                    date: chat.createdAt,
                    type: "bot",
                    chatRating: chat?.ChatRating?.rating,
                },
            ];
        });

        if (Boolean(data?.ChatbotIntegration?.length)) {
            freshChatConfig = data?.ChatbotIntegration?.find(
                (val) => val?.type == "freshchat",
            );
            if (Boolean(Object.keys(freshChatConfig)?.length) && data?.freshChatHeading_status) {
                try {
                    freshChatConfig.user = await (await fetch(`${apiUrl}/api/public/chatbot/${chatbotId}/freshchat/${localStorage.getItem("userId-" + chatbotId)}`)).json();
                } catch (error) {
                    
                }
            }
            addons.square = data?.ChatbotIntegration?.find(
                (val) => val?.type == "square",
            );
        }
        
        await getLeads()
        
        if(leadFields?.every(val => !val.enabled)) {
            leadForm = false;   
            leads = true         
        }

        /*if (!isOpen) {
            toggleChatbot();
            setTimeout(function() {
                //showBot = false;
                messagesBoxStatus = "visible";
            }, 100)
        }*/
    });

    async function getLeads() {
        fetchLeads = await fetch(
            `${apiUrl}/api/public/chatbot/${chatbotId}/leads/${localStorage.getItem("userId-" + chatbotId)}`
        );

        leads = await fetchLeads?.json()
        
        // if(leads) {
            let obj = {
                // id: leads.id,
                chatbotId: chatbotId,
                userId: localStorage.getItem("userId-" + chatbotId),
                location: window.xprops?.parentWindow?.location?.href,
                title: window.xprops?.parentWindow?.document
            }
            await fetch(
                `${apiUrl}/api/public/chatbot/${chatbotId}/leads/${localStorage.getItem(
                    "userId-" + chatbotId,
                )}/activity`,
                {
                    method: "POST",
                    body: JSON.stringify(obj),
                },
            );
        // }

        if(!leads && data?.typeTriggerCaptureLeads == "automatic") {
            leadForm = true;
        }
    }

    async function postMessage(message, userId, sessionId) {
        if(window.xprops?.gaID){
            window.analytics.track('postMessage', {sessionId: sessionId, userId: userId, chatbotId: chatbotId, cookie_flags: 'SameSite=None; Secure',});
        }
            const response = await fetch(
            `${apiUrl}/api/public/chatbot/${chatbotId}/chat`,
            {
                method: "POST",
                body: JSON.stringify({ message, userId, sessionId }),
            }
        );
        
        const headers = response.headers;
        const msgInfo = atob(headers.get("cache-control"));

        let date = "";
        let json = {};
        json = JSON.parse(msgInfo);
        console.log("THIS IS MY JSON", json);
        date = new Date(json.createdAt);
        userStore.set(json.chatUserId);
        sessionStore.set(json.chatSessionId);
        
        const reader = response.body.getReader();

        let text = '';
        let firstRendered = false
        let int;
        try {
            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                // Assuming the response is text, decode and process the chunks of data
                text += new TextDecoder().decode(value);

                if(isJSON(untruncateJson(removeJSONBlockMarker(text)))) {
                    const items = JSON.parse(untruncateJson(removeJSONBlockMarker(text)))
                    if(Boolean(items?.length)) {
                        if(Object.keys(items[items?.length - 1]).length == 6) {
                            messages = [
                                ...messages.slice(0, -1),
                                {
                                    id: json?.id,
                                    // message: text?.[0] == '[' ? untruncateJson(text) : text,
                                    message: removeJSONBlockMarker(text),
                                    date: date,
                                    type: "bot",
                                    isCarouselLoading: true
                                },
                            ];
                        } 
                        const container = document.querySelector('.simplebar-content-wrapper');
                        if(container && container?.scrollTo) {
                            container?.scrollTo({ top: container.scrollHeight + 100, behavior: 'instant' }); 
                        }
                    }
                }else {
                    console.log("UNDER ELSE =========");
                    messages = [
                    ...messages.slice(0, -1),
                    {
                        id: json?.id,
                        // message: text?.[0] == '[' ? untruncateJson(text) : text,
                        message: removeJSONBlockMarker(text),
                        date: date,
                        type: "bot",
                        isCarouselLoading: true,
                        loading: text?.includes("```") ? true : false
                    },
                ];
                }
                // if(!['[', '`']?.includes(text?.[0])) {
                    const container = document.querySelector('.simplebar-content-wrapper');
                    if(container && container?.scrollTo) {
                        container?.scrollTo({ top: container.scrollHeight + 100, behavior: 'smooth' }); 
                    }
                    // messagesBox.scroll({
                    //         top: messagesBox.scrollHeight + 300,
                    //         behavior: "smooth",
                    // });
                // }

                // if(showBot) seenMessage()
                // Do something with the chunk of data (e.g., update UI or process it)
                // console.log('Received chunk of data:', text);
            }

            if(['[', '`']?.includes(text?.[0])) {
                const container = document.querySelector('.simplebar-content-wrapper');
                if(container && container?.scrollTo) {
                    container?.scrollTo({ top: container.scrollHeight + 100, behavior: 'smooth' }); 
                } 
                    messagesBox.scroll({
                            top: messagesBox.scrollHeight + 100,
                            behavior: "smooth",
                    });
            }
            const isObject = isJSON(text?.replace(/<[^>]*>/g, '')) ? JSON.parse(text?.replace(/<[^>]*>/g, '')) : false
            const isPaymentStart = isObject?.startPayment ?? false
            console.log("================================ AFTER WHILE", isObject, isPaymentStart);
            messages = [
                    ...messages.slice(0, -1),
                    {
                        id: json?.id,
                        message: isPaymentStart ? isObject?.message  : text,
                        date: date,
                        type: "bot",
                        isCarouselLoading: false
                    },
            ];
            if(isPaymentStart) {
                messages = [
                    ...messages,
                    {
                        id: json?.id,
                        message: "payment intent goes here",
                        date: date,
                        type: "bot",
                        isCarouselLoading: false,
                        isPayment: true,
                        paymentData: isObject?.data ?? {}
                    },
                ]
            }
            // All data has been read
            if(window.xprops?.gaID){
                    window.analytics.track('botResponses', {'sessionId': sessionId , 'userId' : userId , 'message': message , 'chatbotId': chatbotId , 'botResponse': text , cookie_flags: 'SameSite=None; Secure',});
            }
        } catch (error) {
            console.error('Error reading stream:', error);
        }
    }

    async function updateChatUserStatus (status) {
       try {
        const response =  await ((await fetch(`${apiUrl}/api/public/chatbot/${chatbotId}/chatuser/${localStorage.getItem("userId-" + chatbotId)}/status?status=${status}`)).json())
       } catch (error) {
        console.log("updateChatUserStatus catch", error);
       }
    }

    async function seenMessage () { 
        return await ((await fetch(`${apiUrl}/api/public/chatbot/${chatbotId}/chatuser/${localStorage.getItem("userId-" + chatbotId)}/chat/seen`
        )).json())
    }

    function toggleChatbot() {
        if(chatbotId == "previewOnly") submitBtn.disabled = "disabled";
        showBot = !showBot;
        showBotCount++;

        if (showBot) {
            seenMessage()
            updateChatUserStatus('online')
            window.xprops.openChatSpark();
            window.xprops.resize({ width: 440, height: "100vh" });
            setTimeout(() => {
                animate = "scale-in-br";
            }, 100);
        } else {
            updateChatUserStatus('offline')
            window.xprops.closeChatSpark();
            if(window.xprops?.gaID){
            window.analytics.track('chatSessionEnds', {'sessionId': localStorage.getItem("sessionId-" + chatbotId) , 'userId' : localStorage.getItem("userId-" + chatbotId) , 'chatbotId': chatbotId , cookie_flags: 'SameSite=None; Secure',});
            }
            if (promptsExist) {
                window.xprops.resize({ width: 200, height: 200 });
            } else {
                window.xprops.resize({ width: 65, height: 65 });
            }
            animate = "";
        }
    }

    setTimeout(() => {
        animate = "scale-in-br";
    }, 100);

    async function submitMessage(message) {
        if(data?.typeTriggerCaptureLeads != "automatic" && !leads && data?.leadCaptureHeading_status) {
            // First check if canned messages are being triggered.
            const triggers = data?.SuggestedMessage?.filter(
                (message) => message.type === "TRIGGER_LEAD_MESSAGE"
            );

            const matchingTriggers = triggers.filter((trigger) => {
                const titles = trigger?.title?.split(",");
                return titles?.some((title) => {
                    return message.toLowerCase().includes(title?.toLowerCase().trim());
                });
            });

            if(matchingTriggers?.length > 0) {
                messageInput.value = "";
                leadForm =  true
                showPrompts = false;
                return
            }
        }

        if(data?.freshChatHeading_status && !freshChatConfig.enabled) {
            // First check if canned messages are being triggered.
            const triggers = data?.SuggestedMessage?.filter(
                (message) => message.type === "TRIGGER_FRESHCHAT"
            );

            const matchingTriggers = triggers.filter((trigger) => {
                const titles = trigger?.title?.split(",");
                return titles?.some((title) => {
                    return message.toLowerCase().includes(title?.toLowerCase().trim());
                });
            });

            let text = '';
            if(matchingTriggers?.length > 0) {
                messageInput.value = "";
                freshChatConfig.enabled = true;
                freshChatConfig.messages = [
                    {
                        message: message, 
                        type:  'user', 
                        date: new Date(),
                        id: 1,
                    },
                    {
                        message: freshChatConfig?.subdomain ?? 'Sure, let me summarize the conversation, and connect you to a live agent', 
                        type:  'bot', 
                        date: new Date(),
                        id: 2,
                        chatServer: "freshchat",
                        loading: true
                    }
                ]
                showPrompts = false;
                showOuterPrompts = false;
                setTimeout(() => {
                    messagesBox.scroll({
                        top: messagesBox.scrollHeight,
                        behavior: "smooth",
                    });
                }, 100);
                const response = await fetch(
                    `${apiUrl}/api/public/chatbot/${chatbotId}/chat/summary`,
                    {
                        method: "POST",
                        body: JSON.stringify({ message: "summarise the chat history of me pls", userId: localStorage.getItem("userId-" + chatbotId), sessionId: localStorage.getItem("sessionId-" + chatbotId) }),
                    }
                );
                const reader = response.body.getReader();


                try {
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) {
                            break;
                        }

                        // Assuming the response is text, decode and process the chunks of data
                        text += new TextDecoder().decode(value);
                        const container = document.querySelector('.simplebar-content-wrapper');
                        if(container && container?.scrollTo) {
                            container?.scrollTo({ top: container.scrollHeight + 100, behavior: 'smooth' }); 
                        }
                        messagesBox.scroll({
                                top: messagesBox.scrollHeight,
                                behavior: "smooth",
                        });

                        // Do something with the chunk of data (e.g., update UI or process it)
                        console.log('Received chunk of data:', text);
                    }
                        // All data has been read
                    if(window.xprops?.gaID){

                       window.analytics.track('botResponses', {'sessionId': sessionId , 'userId' : userId , 'message': message , 'chatbotId': chatbotId , 'botResponse': text , cookie_flags: 'SameSite=None; Secure',});
                    }
                    } catch (error) {
                    console.error('Error reading stream:', error);
                }
            }

            let reqObj =  { 
                        message: text, 
                        channelId: freshChatConfig.user?.channel, 
                        userId: freshChatConfig.user?.user?.id, 
                        conversationId: freshChatConfig?.conversationId 
                    }
            const response = await fetch(
                    `${apiUrl}/api/public/chatbot/${chatbotId}/freshchat/${localStorage.getItem("userId-" + chatbotId)}/chat`,
                    {
                        method: "POST",
                        body: JSON.stringify(reqObj),
                    }
                );

            if(matchingTriggers?.length > 0) {
                messageInput.value = "";
                freshChatConfig.enabled = true;
                freshChatConfig.messages = [
                    {
                        message: message, 
                        type:  'user', 
                        date: new Date(),
                        id: 1,
                    },
                    // {
                    //     message: text?.toString(), 
                    //     type:  'bot', 
                    //     date: new Date(),
                    //     id: 2,
                    //     chatServer: "freshchat"
                    // },
                    {
                        message: freshChatConfig?.requestMessage ?? 'Ask Your query to our live agent', 
                        type:  'bot', 
                        date: new Date(),
                        id: 2,
                        chatServer: "freshchat"
                    }
                ]
                showPrompts = false;
                showOuterPrompts = false;
                setTimeout(() => {
                    messagesBox.scroll({
                        top: messagesBox.scrollHeight,
                        behavior: "smooth",
                    });
                }, 100);
                let resultant = await response.json()
                console.log("pollingInterval, resultant", pollingInterval, resultant);
                freshChatConfig.conversationId = resultant?.conversationId
                freshChatConfig.lastMessage = resultant
                if(!pollingInterval) {
                    console.log("polling starting")
                    pollForReplies()
                } 
                return
            }
        }

        if(freshChatConfig.enabled) {
            freshChatConfig.messages.push({
                        message: message, 
                        type:  'user', 
                        date: new Date(),
                        id: new Date().getTime(),
                    },)
            submitBtn.disabled = "disabled";
            messageInput.disabled = "disabled";
            submitting = true
            let reqObj =  { 
                        message, 
                        channelId: freshChatConfig.user?.channel, 
                        userId: freshChatConfig.user?.user?.id, 
                        conversationId: freshChatConfig?.conversationId 
                    }
            
            if(selectedFile) {
                const formData = new FormData();
                formData.append('image', selectedFile, selectedFile?.name)
                const response = await fetch(
                    `${apiUrl}/api/public/chatbot/${chatbotId}/freshchat/${localStorage.getItem("userId-" + chatbotId)}/chat/upload-image`,
                        {
                            method: "POST",
                            body: formData,
                            redirect: 'follow'
                            // headers: newHeaders
                        }
                    );
                const result = await response.json()
                console.log("result ", result);
                reqObj.image = result?.data?.url
            }

            const response = await fetch(
                `${apiUrl}/api/public/chatbot/${chatbotId}/freshchat/${localStorage.getItem("userId-" + chatbotId)}/chat`,
                {
                    method: "POST",
                    body: JSON.stringify(reqObj),
                }
            );
            selectedFile = null
            const resultant = await response.json()
            freshChatConfig.messages[freshChatConfig.messages?.length - 1] = resultant
            freshChatConfig.conversationId = resultant?.conversationId
            freshChatConfig.lastMessage = resultant
            messageInput.value = "";
            submitBtn.disabled = "";
            messageInput.disabled = "";
            submitting = false
            if(!Boolean(parseFloat(resultant?.isAgentAvailable))) {
                freshChatConfig.messages?.push({
                        message: "All agents are offline but they will get back to you", 
                        type:  'bot', 
                        date: new Date(),
                        id: 2,
                        chatServer: "freshchat"
                    })
                if(pollingInterval) stopPolling()
                if(!leads && data?.leadCaptureHeading_status) {
                    leadForm = true
                    showPrompts = false;
                }else {
                    messages = [...messages, ...freshChatConfig?.messages]
                    freshChatConfig.messages = []
                    freshChatConfig.enabled = false;
                }
            }
            if(!pollingInterval) {
                console.log("polling starting")
                pollForReplies()
            } 
            console.log("resultant", resultant, pollingInterval);
            return
        }

        messages = [...messages, { message, date: new Date(), type: "user" }];
        messageInput.value = "";
        submitBtn.disabled = "disabled";
        messages = [
            ...messages,
            { loading: true, message: "", date: new Date(), type: "bot" },
        ];
        showPrompts = false;
        showOuterPrompts = false;

        setTimeout(() => {
            const container = document.querySelector('.simplebar-content-wrapper');
            if(container && container?.scrollTo) {
                container?.scrollTo({ top: container.scrollHeight + 100, behavior: 'smooth' }); 
            }
            messagesBox.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }, 100);

        await postMessage(
            message,
            localStorage.getItem("userId-" + chatbotId),
            localStorage.getItem("sessionId-" + chatbotId)
        );
        submitBtn.disabled = "";
        messageInput.focus();
    }

    async function handleOnSubmit(e) {
        const formData = new FormData(e.target);
        const parsedData = {};
        for (let field of formData) {
            const [key, value] = field;
            parsedData[key] = value;
        }
        const { message } = parsedData;

        if (message) {
            submitMessage(message);
        }
    }

    function relativeTime(date) {
        return dayjs(date).fromNow();
    }

    async function onNextPress(value, index) {
        if(value) {
            if(leadFields[index]?.regex) {
                const isValid = leadFields[index].regex?.test(value)
                if(!isValid) {
                    leadFields[index].error = leadFields[index].errorMessage
                    return
                }else {
                    leadFields[index].error = false
                }
            }

            leadFields[index].error = false

            if(index != leadFields?.map((item) => item.enabled).lastIndexOf(true)) {
                leadFields[index].value = value;
                leadFields[index].editable = false;
                leadFields[index + 1].show = true;
            }else {
                leadFields[index].value = value;
                leadFields[index].editable = false;
                leadFields[index].loading = true;

                let leadData = leadFields?.reduce((prevValue, currValue) => {
                    if(currValue.enabled) {
                        prevValue[currValue.postKey] = currValue.value
                        return prevValue
                    }else {
                        return prevValue
                    }
                }, {})

                leadData = {
                    ...leadData,
                    chatUserId: localStorage.getItem("userId-" + chatbotId),
                }
                await fetch(
                        `${apiUrl}/api/public/chatbot/${chatbotId}/leads`,
                        {
                                method: "POST",
                                body: JSON.stringify(leadData),
                            }
                        );
                if(freshChatConfig.enabled) {
                    if(leadData?.name) {
                        leadData.first_name = leadData?.name?.split(' ')?.[0]
                        leadData.last_name = leadData?.name?.split(' ')?.[1] ?? ""
                        delete leadData.name
                    }
                    if(window.xprops?.gaID){
                        window.analytics.track('Freshchat', {'sessionId': localStorage.getItem("sessionId-" + chatbotId) , 'userId' : localStorage.getItem("userId-" + chatbotId) , 'first_name': leadData.first_name , 'last_name': leadData.last_name , 'company_name': leadData.companyName , cookie_flags: 'SameSite=None; Secure', });
                    }
                    if(leadData?.companyName) delete leadData.companyName;
                    await fetch(
                    `${apiUrl}/api/public/chatbot/${chatbotId}/freshchat/${localStorage.getItem("userId-" + chatbotId)}`,
                    {
                        method: "POST",
                        body: JSON.stringify({ 
                            userId: freshChatConfig?.user?.user?.id, 
                            ...leadData
                        }),
                    }
                    );
                    messages = [...messages, ...freshChatConfig?.messages]
                    freshChatConfig.messages = []
                    freshChatConfig.enabled = false;
                }
                getLeads()
                leadFields[index].loading = false;
                showCompletedMessage = true;
                messages = [
                    ...messages,
                    ...[{
                        message: freshChatConfig?.leadCaptureCompletedMessage ?? 'Thanks for sharing your information', 
                        type:  'bot', 
                        date: new Date(),
                        id: new Date().getTime(),
                        chatServer: "freshchat"
                    }, 
                    {
                        message: freshChatConfig?.leadCaptureContinueMessage ?? 'Anything else you want to ask?', 
                        type:  'bot', 
                        date: new Date(),
                        id: new Date().getTime(),
                        chatServer: "freshchat"
                    }]]
                if(window.xprops?.gaID){
                   window.analytics.track('leadCapture', {sessionId: localStorage.getItem("sessionId-" + chatbotId), userId: localStorage.getItem("userId-" + chatbotId), chatbotId: chatbotId, companyName:leadData?.companyName , name:leadData?.name , email:leadData?.email , phone:leadData?.phone ,  cookie_flags: 'SameSite=None; Secure'});
                }
                leadForm = false

            }
        }else {
            leadFields[index].error = leadFields[index].errorMessage
        }
    }

    // await fetch(`${apiUrl}/api/public/chatbot/${chatbotId}/freshchat/${localStorage.getItem("userId-" + chatbotId)}/chat?conversationId=ecccf15b-9047-4493-b51d-45d43a16d14c&lastMessageId=${freshChatConfig?.lastMessage?.id}`)
    
    const fetchNewReplies = async () => {
        try {
            const response = await fetch(
                `${apiUrl}/api/public/chatbot/${chatbotId}/freshchat/${localStorage.getItem("userId-" + chatbotId)}/chat?conversationId=${freshChatConfig.conversationId}&lastMessageId=${freshChatConfig?.lastMessage?.id}&agentId=${freshChatConfig?.agentId}`,
            );
            const replyData = await response.json();

            if (Boolean(Object.keys(replyData)?.length)) {
                if(freshChatConfig.messages?.filter(msg => msg?.id == replyData?.id)?.length < 1) {
                    freshChatConfig.messages?.push(replyData);
                    freshChatConfig.lastMessage = replyData;
                }
                if (
                    freshChatConfig?.agentId != replyData?.agentId &&
                    Boolean(replyData?.agentId)
                ) {
                    freshChatConfig.agentId = replyData?.agentId;
                    freshChatConfig.agent = replyData?.agent;
                }
                if(replyData?.agent?.name) {
                    if(!Boolean(data?.oldBotName)) data.oldBotName = data.botName
                    data.botName = replyData?.agent?.name;
                    data.oldBotAvatar = data.botAvatar
                    data.botAvatar = replyData?.agent?.avatar ?? data.botAvatar
                    console.log("data.botName", data.botName, data.botAvatar);
                }

                if (replyData?.isResolved) {
                    stopPolling();
                    messages = [...messages, ...freshChatConfig?.messages];
                    freshChatConfig.messages = [];
                    freshChatConfig.enabled = false;
                    data.botName = data.oldBotName;
                    data.botAvatar = data.oldBotAvatar;
                }
                setTimeout(() => {
                    const container = document.querySelector('.simplebar-content-wrapper');
                    if(container && container?.scrollTo) {
                        container?.scrollTo({ top: container.scrollHeight + 100, behavior: 'smooth' }); 
                    }
                    messagesBox.scroll({
                        top: messagesBox.scrollHeight,
                        behavior: "smooth",
                    });
                }, 100);
            } else {
                if (
                    freshChatConfig.lastMessage?.date &&
                    dayjs().diff(
                        dayjs(freshChatConfig.lastMessage?.date),
                        "minutes",
                    ) >= 5
                ) {
                    stopPolling();
                    messages = [...messages, ...freshChatConfig?.messages];
                    freshChatConfig.messages = [];
                    freshChatConfig.enabled = false;
                    data.botName = data.oldBotName;
                    data.botAvatar = data.oldBotAvatar;
                }
            }
        } catch (error) {
            console.error("Error fetching new replies:", error);
        }
    };


    const stopPolling = () => {
        clearInterval(pollingInterval);
        console.log('Polling stopped due to no replies within 5 minutes.');
    };

    const pollForReplies = () => {
        pollingInterval = setInterval(async () => {
            await fetchNewReplies();
        }, 3000); // Polling interval (adjust as needed)
    };

    // Start polling for new replies when your Svelte app initializes
    // pollForReplies();

    async function openFileExplorer() {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    }

    async function handleFileSelect(event) {
        selectedFile = event.target?.files?.[0];
        if(window.xprops?.gaID){
            window.analytics.track('freshchatFileUpload', {'sessionId': localStorage.getItem("sessionId-" + chatbotId) , 'userId' : localStorage.getItem("userId-" + chatbotId) , 'chatbotId': chatbotId , 'selectedFile_name': selectedFile?.name , cookie_flags: 'SameSite=None; Secure',}); 
        }
 
        // Handle the selected file here, e.g., display preview, upload, etc.
    }
    
    function isJSON(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (error) {
            return false;
        }
    }


    // Function to automatically resize textarea
    function autosize(event) {
        const textarea = event.target;
        const lines = textarea?.value?.split('\n');
        textarea.style.height = (lines?.length > 1 || textarea?.value?.length > 52) ? textarea?.scrollHeight + 'px' : '40px';
    }

    // Attach autosize function to input event on textareas with class 'autosize'
    document.addEventListener('input', function(event) {
        if (event.target && event.target.matches('.autosize')) {
            autosize(event);
            const textarea = event.target;
        }
    });

    // Adjust the height of all textareas with the 'autosize' class on page load
    window.addEventListener('DOMContentLoaded', function() {
 
        const interval = this.setInterval(() => {
            const container = document.querySelector('.simplebar-content-wrapper');
            if(container && container?.scrollTo) {
                container.scrollTo({ top: container.scrollHeight + 100, behavior: 'smooth' }); 
            }

            messageInput?.addEventListener('keydown', function(event) {
                    if (event.key === 'Enter') {
                        if (event.shiftKey) {
                            messageInput.value = messageInput.value + '\n'
                            autosize({target: null});
                            event.preventDefault();
                        } else {
                            event.preventDefault();
                            if(messageInput.value && !submitBtn?.disabled) {
                                submitMessage(messageInput.value)
                                messageInput.value = ""
                                autosize({target: messageInput});
                                console.log("submit form");
                                event.preventDefault(); 
                            }
                        }
                    }
            });

            if(container && messageInput) {
                this.clearInterval(interval)
            }
        }, 1000)

        document.querySelectorAll('.autosize').forEach(textarea => {
            textarea.style.height = 'auto'; // Reset height to auto
            textarea.style.height = textarea.scrollHeight + 'px'; // Set height to content's scroll height
        });
    });
</script>

{#if fullScreen}
    <style>
        html,
        body {
            width: 100%;
            height: 100%;
        }
    </style>
{/if}

<style>
    .promptCloser {
        background-color: var(--prompt-bg-default);
        fill: var(--prompt-text-default);
    }
    .promptCloser:hover {
        background-color: var(--prompt-bg-hover);
        fill: var(--prompt-text-hover);
    }

    .spin {
        animation: spinAnimation 1s linear infinite; /* Define the spin animation */
    }

    @keyframes spinAnimation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>

<!-- {console.log("THIS IS MY LOG", data)} -->
{#if dataLoaded && !data.error}
    <div
        class="sparkchat pl-[5px] pr-[5px] pb-[5px] z-[2147483647] {fullScreen
            ? 'w-full h-full'
            : 'w-auto h-auto'}  flex flex-col justify-end {messagesBoxStatus}"
    >
        <!-- chat box -->
        <div
            style={!isHelpCenterView ? `box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);` : ""}
            class="{fullScreen
                ? 'w-full h-full'
                : 'w-full max-w-[430px] h-[99vh]'} flex-col bg-white rounded-[6px] {animate} {showBot
                ? 'flex opacity-0'
                : 'hidden opacity-0'}"
        >
            {#if !isHelpCenterView}
            <div
                style="background-color: {scheme.header};"
                class="flex items-center justify-between pt-[10px] pl-[15px] pr-[15px] pb-[8px] rounded-t-[6px] user-info"
            >
                <!-- user info -->
                <div class="flex items-center w-full">
                    <span class="relative inline-flex">
                        <img
                            class="rounded-full w-[32px] h-[32px]"
                            src={data.botAvatar}
                            alt={`AI Bot Avatar for ${data.botName}`}
                        />
                        <span
                            class="absolute bottom-0 right-0 flex h-[8px] w-[8px]"
                        >
                            <span
                                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
                            />
                            <span
                                class="relative inline-flex rounded-full h-[8px] w-[8px] border border-white bg-green-500"
                            />
                        </span>
                    </span>
                    <div class="pl-2">
                        <div>
                            <span
                                style="color: {scheme.headerText};"
                                class="text-white text-[16px] font-medium"
                                >{data.botName}</span
                            >
                        </div>
                    </div>
                    <div class="grow inline-flex justify-end">
                        <button on:click={toggleChatbot}>
                            <span
                                class="{showBot
                                    ? 'inline'
                                    : 'hidden'} cross-icon"
                            >
                                <svg
                                    class="w-[14px] h-[14px]"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill={scheme.headerText}
                                        d="M13.2725 11.8998L23.4353 1.73702C23.7863 1.38602 23.7863 0.815422 23.4353 0.464422C23.0843 0.113422 22.5137 0.113422 22.1627 0.464422L11.9999 10.6272L1.83712 0.464422C1.48612 0.113422 0.915519 0.113422 0.564519 0.464422C0.21352 0.815422 0.21352 1.38602 0.564519 1.73702L10.7273 11.8998L0.564519 22.0626C0.21352 22.4136 0.21352 22.9842 0.564519 23.3352C0.740919 23.5116 0.971319 23.598 1.20172 23.598C1.43212 23.598 1.66252 23.5098 1.83892 23.3352L12.0017 13.1724L22.1645 23.3352C22.3409 23.5116 22.5713 23.598 22.8017 23.598C23.0321 23.598 23.2625 23.5098 23.4389 23.3352C23.7899 22.9842 23.7899 22.4136 23.4389 22.0626L13.2761 11.8998H13.2725Z"
                                    />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <!-- end user info -->
            </div>
            {/if}

            
            <div bind:this={messagesBox} data-simplebar  style="scrollbar-width: none;" class={`flex-1 px-[15px] py-[25px] overflow-y-auto messages-box scroll-smooth`}>
                <div
                    use:inview
                    on:inview_change={(event) => {
                        const { observer, node } = event.detail;

                        const style = window.getComputedStyle(node);
                        //alert(style.visibility);
                        //if (style.visibility !== 'hidden') {
                            const container = document.querySelector('.simplebar-content-wrapper');
                            if(container && container?.scrollTo) {
                                container?.scrollTo({ top: container.scrollHeight + 100, behavior: 'smooth' }); 
                            }
                            
                            messageInput.focus();
                            messagesBox.scroll({
                                top: messagesBox.scrollHeight,
                            });
                        //}
                    }}
            >
                {#if data.welcomeMessage && data?.welcomeMessageHeading_status}<BotMessage
                        welcomeMessage={true}
                        {scheme}
                        message={data.welcomeMessage}
                        botAvatar={data.botAvatar}
                    />{/if}
                
                {#if chatbotId == "previewOnly"}
                    <UserMessage
                        {scheme}
                        message={"This is preview user message"}
                        date={relativeTime(new Date())}
                    />
                    <BotMessage
                        {scheme}
                        {chatbotId}
                        chatId={1}
                        message={"This is preview bot message"}
                        botAvatar={data.botAvatar}
                        date={relativeTime(new Date())}
                    />
                {/if}
                {#each messages as message, index}
                    {#if message.type === "bot"}

                        <!-- {#if index == (messages?.length - 1)}
                            {console.log("helllo", isJSON(untruncateJson(removeJSONBlockMarker(message.message))) ? (JSON.parse(untruncateJson(removeJSONBlockMarker(message.message))), message.message) : `not proper json ${message?.message}` )}
                        {/if} -->

                        {#if isJSON(untruncateJson(removeJSONBlockMarker(message.message)))}
                            <CarouselMessage chatbotId={chatbotId} scheme={scheme} chatBotData={data} 
                            message={{...message, message: untruncateJson(removeJSONBlockMarker(message.message))}} />
                            <!-- <p>Carousel</p> -->
                        {:else}
                            <BotMessage
                                loading={message.loading}
                                {scheme}
                                {chatbotId}
                                chatId={message.id}
                                message={message.message}
                                botAvatar={data.botAvatar}
                                date={(message.date) ? relativeTime(message.date) : ""}
                                chatRating={message?.chatRating}
                                welcomeMessage={message?.chatServer == "freshchat"}
                                isPayment={message?.isPayment}
                                paymentData={message?.paymentData}
                            />
                        {/if}

                    {:else}
                        {#if message.message != "reply-from-chat-history"}
                            <UserMessage
                                {scheme}
                                message={message.message}
                                date={relativeTime(message.date)}
                            />
                        {/if}
                    {/if}
                {/each}

                {#if freshChatConfig.enabled}
                    {#each freshChatConfig?.messages as message}
                        {#if message.type === "bot" && !isJSON(message.message)}
                            <BotMessage
                                loading={message.loading}
                                {scheme}
                                {chatbotId}
                                chatId={message.id}
                                message={message.message}
                                botAvatar={data.botAvatar}
                                date={(message.date) ? relativeTime(message.date) : ""}
                                chatRating={message?.chatRating}
                                welcomeMessage={message?.chatServer == "freshchat"}
                            />
                        {:else}
                            <UserMessage
                                {scheme}
                                message={message.message}
                                date={relativeTime(message.date)}
                            />
                        {/if}
                    {/each}
                {/if}

                {#if leadForm && data?.leadCaptureHeading_status}
                    {#if data?.typeTriggerCaptureLeads != "automatic"}    
                        <div>
                            <BotMessage
                                welcomeMessage={true}
                                {scheme}
                                message={data.leadCaptureRequestMessage}
                                botAvatar={data.botAvatar}
                            />
                            {#each leadFields as item, index}
                                {#if item.enabled && item.show}
                                    <div key={index}>
                                        <LeadCaptureField 
                                            editable={item?.editable} 
                                            {index} 
                                            onNextPress={onNextPress} 
                                            {scheme} 
                                            loading={item?.loading}
                                            title={item.title}
                                            error={item?.error}   
                                        />
                                    </div>
                                {/if}
                            {/each}
                            {#if showCompletedMessage}
                                <div>
                                    <BotMessage
                                        welcomeMessage={true}
                                        {scheme}
                                        message={data.leadCaptureCompletedMessage}
                                        botAvatar={data.botAvatar}
                                    />
                                    <BotMessage
                                        welcomeMessage={true}
                                        {scheme}
                                        message={data.leadCaptureContinueMessage}
                                        botAvatar={data.botAvatar}
                                    />
                                </div>
                            {/if}
                        </div>
                    {:else if messages?.length == 2}
                        <div>
                            <BotMessage
                                welcomeMessage={true}
                                {scheme}
                                message={data.leadCaptureRequestMessage}
                                botAvatar={data.botAvatar}
                            />
                            {#each leadFields as item, index}
                                {#if item.enabled && item.show}
                                    <div key={index}>
                                        <LeadCaptureField 
                                            editable={item?.editable} 
                                            {index} 
                                            onNextPress={onNextPress} 
                                            {scheme} 
                                            loading={item?.loading}
                                            title={item.title}
                                            error={item?.error}   
                                        />
                                    </div>
                                {/if}
                            {/each}
                            {#if showCompletedMessage}
                                <div>
                                    <BotMessage
                                        welcomeMessage={true}
                                        {scheme}
                                        message={data.leadCaptureCompletedMessage}
                                        botAvatar={data.botAvatar}
                                    />
                                    <BotMessage
                                        welcomeMessage={true}
                                        {scheme}
                                        message={data.leadCaptureContinueMessage}
                                        botAvatar={data.botAvatar}
                                    />
                                </div>
                            {/if}
                        </div>
                    {/if}
                {/if}

                {#if showBot && showPrompts && promptsExist && data?.suggestedMessageHeading_status && data.chatbotType == "default"}
                    <div class="w-2/3 float-right">
                        <SuggestedPrompts
                            prompts={data?.SuggestedMessage?.filter(
                                (prompt) => prompt.type === "SUGGESTED_MESSAGE"
                            )}
                            {toggleChatbot}
                            {submitMessage}
                            {showBot}
                            {lightness}
                            {scheme}
                            chatbotId = {data.id}
                        />
                    </div>
                {/if}
                </div>
            </div>

            <div
                class="flex flex-col text-[#4A5568] text-[10px] leading-[24px] items-center {isHelpCenterView ? "border-t-[1px] border-[#E2E8F0] pt-[20px] pb-[25px]" : (data?.removeBranding ? "pt-[7px] pb-[15px] px-[16px]" : "pt-[7px] pb-[10px] px-[16px]")}"
            >   
                {#if selectedFile}
                    <div class="flex self-start items-center">
                        <svg
                        viewBox="0 0 24 24"
                        class="h-[16px] w-[16px]"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M20 10.9696L11.9628 18.5497C10.9782 19.4783 9.64274 20 8.25028 20C6.85782 20 5.52239 19.4783 4.53777 18.5497C3.55315 17.6211 3 16.3616 3 15.0483C3 13.7351 3.55315 12.4756 4.53777 11.547L12.575 3.96687C13.2314 3.34779 14.1217 3 15.05 3C15.9783 3 16.8686 3.34779 17.525 3.96687C18.1814 4.58595 18.5502 5.4256 18.5502 6.30111C18.5502 7.17662 18.1814 8.01628 17.525 8.63535L9.47904 16.2154C9.15083 16.525 8.70569 16.6989 8.24154 16.6989C7.77738 16.6989 7.33224 16.525 7.00403 16.2154C6.67583 15.9059 6.49144 15.4861 6.49144 15.0483C6.49144 14.6106 6.67583 14.1907 7.00403 13.8812L14.429 6.88674" stroke={baseColors.primary} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        {selectedFile?.name}
                    </div>
                {/if}
                <form
                    use:inview
                    on:inview_enter={() => {
                        messageInput.focus();
                    }}
                    on:submit|preventDefault={handleOnSubmit}
                    class="relative w-full"
                >
                    <span
                        class="absolute inset-y-0 right-0 flex items-center bottom-[5px] {!isHelpCenterView ? "pr-[16px]" : ""}"
                    >
                        {#if !isHelpCenterView}
                        <div style="display: flex; position: absolute; bottom: 15px; right: 15px;" class="gap-2">
                           {#if freshChatConfig?.enabled}
                            <div onclick={openFileExplorer} >
                                <label for="fileInput" class="cursor-pointer">
                                    <svg  viewBox="0 0 24 24" class="h-[20px] w-[20px]" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="{submitBtn?.disabled ? '#808080' : baseColors.primary}"
                                    fill-rule="evenodd" clip-rule="evenodd"
                                    d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5ZM7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10ZM10.3536 13.3536L12.5 15.5L18.1464 9.85355C18.4614 9.53857 19 9.76165 19 10.2071V19H5V18L9.64645 13.3536C9.84171 13.1583 10.1583 13.1583 10.3536 13.3536Z" />
                                    </svg>
                                </label>
                                <input 
                                        type="file" 
                                        id="fileInput" 
                                        style="display: none;" 
                                        on:change={handleFileSelect} 
                                        class="hidden" 
                                        accept=".png, .jpeg, .jpg, .gif"
                                    />
                            </div>
                           {/if}
                            <button
                                bind:this={submitBtn}
                                type="submit"
                                class="focus:outline-none focus:shadow-outline"
                            >
                            {#if !submitting}
                                <svg
                                    viewBox="0 0 16 16"
                                    class="h-[16px] w-[16px]"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill={submitBtn?.disabled ? "#808080" : baseColors.primary}
                                        d="M14.8752 0.0994954L0.390799 8.45574C-0.174826 8.78074 -0.102951 9.56824 0.459549 9.80574L3.78142 11.1995L12.7596 3.28699C12.9314 3.13387 13.1752 3.36824 13.0283 3.54637L5.50017 12.7182V15.2339C5.50017 15.9714 6.3908 16.262 6.8283 15.7276L8.81268 13.312L12.7064 14.9432C13.1502 15.11 13.6564 14.8526 13.7377 14.3745L15.9877 0.874495C16.0939 0.243245 15.4158 -0.213005 14.8752 0.0994954Z"
                                    />
                                </svg>
                            {:else}
                                <svg
                                    class="spin"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke={baseColors.primary}
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path d="M21 12a9 9 0 11-6.219-8.56" />
                                </svg>
                            {/if}
                            </button>
                        </div>
                        {:else}
                        <button
                            bind:this={submitBtn}
                            type="submit"
                            class={submitBtn?.disabled ? "focus:outline-none focus:shadow-outline bg-[#808080] rounded py-[12px] px-[20px] text-slate-50 text-base font-semibold leading-none" : "focus:outline-none focus:shadow-outline bg-[#3182CE] rounded py-[12px] px-[20px] text-slate-50 text-base font-semibold leading-none"}
                        >Send
                        </button>
                        {/if}
                    </span>
                    <div class="w-full">
                        {#if !isHelpCenterView}
                        <textarea
                            bind:this={messageInput}
                            name="message"
                            class="w-full rounded-[6px] border border-[#E2E8F0] text-[#718096] text-[14px] leading-[20px] py-[10px] px-[16px] pr-[40px] autosize"
                            type="text"
                            value=""
                            placeholder="Aa"
                            autocomplete="off"
                            style="scrollbar-width: none; outline: none; overflow: hidden; height: 40px"
                        />
                        {:else}
                        <textarea
                            bind:this={messageInput}
                            name="message"
                            class="w-[90%] text-[#718096] text-[14px] leading-[20px]"
                            type="text"
                            value=""
                            placeholder="Reply to Sparkbot"
                            style="scrollbar-width: none; outline: none"
                            autocomplete="off"
                        />
                        {/if}
                    </div>
                </form>
                {#if !isHelpCenterView && !data?.removeBranding}
                <span class="mt-[9px]"
                    >Powered by <strong
                        ><a href="http://chatspark.io" target="_blank"
                            >ChatSpark</a
                        ></strong
                    ></span
                >
                {/if}
            </div>
        </div>
        <!-- end chat box -->

        {#if !showBot && showOuterPrompts && promptsExist && data?.suggestedMessageHeading_status && data.chatbotType == "default"}
            <div class="mb-5">
                <button 
                    on:click={() => showOuterPrompts = false}
                    class="mb-1"
                >
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    style="
                        border-radius: 10px;
                        position: absolute;
                        right: 10px;
                        top: 0px;
                    " 
                    style:--prompt-bg-default={lightness(scheme.header, 90)}
                    style:--prompt-bg-hover={scheme.header}
                    style:--prompt-text-default={scheme.header}
                    style:--prompt-text-hover={scheme.headerText}
                    width="20" height="20" 
                    fill="{scheme.header}" class="promptCloser bi bi-x" viewBox="0 0 16 16"
                > 
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> 
                </svg>
                </button>
                <SuggestedPrompts
                    prompts={data?.SuggestedMessage?.filter(
                        (prompt) => prompt.type === "SUGGESTED_MESSAGE"
                    )}
                    {toggleChatbot}
                    {submitMessage}
                    {showBot}
                    {lightness}
                    {scheme}
                />
            </div>
        {/if}

        <!-- start chat bubble buttons -->
        <button
            style="background-color: {scheme.header}; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
            class="{showBot
                ? 'hidden'
                : 'flex'} self-end h-[60px] w-[60px] min-h-[60px] min-w-[60px] items-center justify-center rounded-full text-white"
            on:click={toggleChatbot}
        >
            <span class="{showBot ? 'inline' : 'hidden'} cross-icon">
                <svg
                    class="w-[24px] h-[24px]"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill={scheme.headerText}
                        d="M13.2725 11.8998L23.4353 1.73702C23.7863 1.38602 23.7863 0.815422 23.4353 0.464422C23.0843 0.113422 22.5137 0.113422 22.1627 0.464422L11.9999 10.6272L1.83712 0.464422C1.48612 0.113422 0.915519 0.113422 0.564519 0.464422C0.21352 0.815422 0.21352 1.38602 0.564519 1.73702L10.7273 11.8998L0.564519 22.0626C0.21352 22.4136 0.21352 22.9842 0.564519 23.3352C0.740919 23.5116 0.971319 23.598 1.20172 23.598C1.43212 23.598 1.66252 23.5098 1.83892 23.3352L12.0017 13.1724L22.1645 23.3352C22.3409 23.5116 22.5713 23.598 22.8017 23.598C23.0321 23.598 23.2625 23.5098 23.4389 23.3352C23.7899 22.9842 23.7899 22.4136 23.4389 22.0626L13.2761 11.8998H13.2725Z"
                    />
                </svg>
            </span>

            <span class="{!showBot ? 'inline' : 'hidden'} chat-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    class="w-[24px] h-[24px]"
                >
                    <path
                        fill={scheme.headerText}
                        d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64z"
                    />
                </svg>
            </span>
        </button>
        <!-- end chat bubble buttons -->
    </div>
{/if}
